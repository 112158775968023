<template>
  <teleport to="#header-action">
    <Btn
      v-if="helperService.userHasPermission('fichier_add,essaifichier_edit')"
      class="action-btn"
      icon="plus"
      color="primary"
      text="Ajouter un fichier"
      @click="openModal('addFile', {})"
    />
  </teleport>

  <List
    :api="list.api"
    :header="list.headers"
    :items="list.col"
    :item-key="list.key"
  >
    <template v-slot:group-actions="{ selectedItems }">
      <Btn
        text="Supprimer"
        icon="trash-alt"
        color="default"
        @click="openModal('deleteItems', selectedItems)"
      />
    </template>

    <template v-slot:media="{ item }">
      {{ item.media?.extension }}
    </template>

    <template v-slot:categorie="{ item }">
      {{ item.categorie?.nom }}
    </template>

    <template v-slot:actions="{ item }">
      <ToolDropdown>
        <MenuItem
          v-if="helperService.userHasPermission('essaifichier_show,fichier_show')"
          :item-data="{
            label: 'Consulter',
            iconName: 'visibility'
          }"
          @click="openModal('viewFile', item)"
        />
        <MenuItem
          v-if="helperService.userHasPermission('essaifichier_show,fichier_show')"
          :item-data="{
            label: 'Télécharger',
            iconName: 'download'
          }"
          @click="downloadFile(item)"
        />
        <MenuItem
          v-if="helperService.userHasPermission('essaifichier_edit,fichier_edit')"
          :item-data="{
              label: 'Éditer',
              iconName: 'create',
          }"
          @click="openModal('addFile', item)"
        />
        <MenuItem
          v-if="helperService.userHasPermission('fichier_remove')"
          :item-data="{
                  label: 'Supprimer',
                  iconName: 'trash-alt',
              }"
          @click="openModal('deleteFile', item)"
        />
      </ToolDropdown>

    </template>
  </List>

  <!-- Modals -->

  <!-- View/Download file -->
  <Modal
    title="Consulter / Télécharger le fichier"
    :active="modal.viewFile"
    :data="modalData"
    @modal-close="modal.viewFile = false"
  >
    <template
      v-slot:modal-body="{ data }"
    >
      <ImageToken
        v-if="['jpg', 'jpeg', 'png', 'svg'].includes(data?.media?.extension)"
        :image="data?.media"
        :alt="data?.nom"
        size="full"
      />
      <p v-else>Aucun aperçu n'est disponible pour ce type de fichier.</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.viewFile = false" />
      <Btn
        text="Télécharger"
        @click="downloadFile(data)"
        icon="download"
        color="primary"
      />
    </template>
  </Modal>
  <!-- Ajouter un fichier-->
  <Modal
    :key="componentKey"
    :title="modal.addFileTitle"
    :active="modal.addFile"
    :data="modalData"
    :containForm="true"
    :modalValidationSchema="validationSchema"
    @modal-close="modal.addFile = false"
    @modal-form-submitted="handleSubmit($event)"
  >
    <template v-slot:modal-body="{ data }">
      <Input id="description" label="Nom" />

      <SelectExtended
        id="categorie"
        label="Catégorie"
        option-key="id"
        option-value="nom"
        apiEndpoint="dictionnaire/categorie/fichier"
      />

      <InputMedia
        v-if="modal.addFile"
        type="file"
        id="media"
        label="Choisissez le fichier à ajouter"
        :media="data?.media"
        textInfo="Fichier de 10Mo maximum"
        @update:modelValue="setMedia($event)"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.addFile = false" />
      <Btn
        btnType="submit"
        :text="textButton"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Delete file -->
  <Modal
    title="Supprimer le fichier&nbsp;?"
    :active="modal.deleteFile"
    :data="modalData"
    @modal-close="modal.deleteFile = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer le fichier&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteFile = false" />
      <Btn
        text="Supprimer"
        @click="deleteItem(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Groupe d'actions de suppression -->
  <Modal
    title="Supprimer des fichiers&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer ces fichiers&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import ImageToken from '@/components/base/ImageToken.vue'
import InputMedia from '@/components/form/InputMedia.vue'

export default {
  name: 'EssaiFilesView',

  components: {
    InputMedia,
    ImageToken,
    SelectExtended,
    Input,
    MenuItem,
    ToolDropdown,
    Modal,
    List,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object({
      description: this.yup.string().required().nullable(),
      categorie: this.yup.object().required().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        api: {
          endpoint: `essai/${this.$route.params.id}/fichier`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom du document' },
          { label: 'Type' },
          { label: 'Catégorie' },
        ],
        col: ['description', 'media', 'categorie'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        viewFile: false,
        addFile: false,
        addFileTitle: 'Ajout d\'un fichier',
      },
      protocol: {},
      method: 'post',
      endpoint: 'fichier',
      componentKey: 0,
      textButton: 'Ajouter',
      validationSchema,
      onSubmit,
      isSubmitting,
      errors,
    }
  },

  mounted() {
    this.getEssai()
  },

  methods: {
    setMedia(event) {
      this.file = event
    },

    openModal(modal, data = {}) {
      const handleData = { ...data }
      if (modal !== 'deleteItems') {
        if (Object.keys(data).length > 0) {
          this.modal.addFileTitle = 'Modifier le fichier'

          handleData.categorie = {
            key: data.categorie.id,
            value: data.categorie.nom,
          }

          this.file = data.media
          this.method = 'put'
          this.endpoint = `fichier/${data.id}`
          this.textButton = 'Modifier'
        } else {
          this.modal.addFileTitle = 'Ajouter un fichier'

          this.method = 'post'
          this.endpoint = 'fichier'
          this.textButton = 'Ajouter'
        }
      }

      this.modalData = handleData
      this.modal[modal] = true
    },

    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
      })
    },

    downloadFile(item) {
      this.fileService.downloadFile(`utilitaire/media/${item.media.jeton}.telecharger`, item.media.nom)
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')

      // Add = 'undefined', Edit = 'null'
      if (typeof this.file === 'undefined' || this.file === null) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le fichier est obligatoire.',
        })

        this.emitter.emit('close-loader')
      } else {
        this.fetchService[this.method](this.endpoint, {
          description: values.description,
          categorie_id: values.categorie.key,
          media_id: this.file.id,
        }).then((response) => {
          this.fetchService.put(`essai/${this.$route.params.id}/fichier`, {
            fichiers: [
              {
                id: response.data.id,
              },
            ],
          }).then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `Le fichier a bien été ${this.method === 'put' ? 'modifié' : 'ajouté'}.`,
            })

            this.modal.addFile = false
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          })
        })
      }
    },

    deleteItem(item) {
      this.emitter.emit('open-loader')

      this.fetchService.get(`essai/${this.$route.params.id}/fichier`).then((response) => {
        let filteredFiles = response.data.filter((file) => file.id !== item.id)

        filteredFiles = filteredFiles.map((filteredFile) => ({ id: filteredFile.id }))

        this.fetchService.put(`essai/${this.$route.params.id}/fichier`, {
          fichiers: filteredFiles,
        }).then(() => {
          this.fetchService.delete(`fichier/${item.id}`).then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le fichier a bien été supprimé.',
            })

            this.emitter.emit('list-refresh')
          })
        })
      })

      this.emitter.emit('close-loader')
      this.modal.deleteFile = false
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.put(`essai/${this.$route.params.id}/fichier`, {
          fichiers: itemsToDelete.map((file) => ({ id: file })),
        }).then(() => {
          this.fetchService.delete(`fichier/${item.id}`).then(() => {
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        })
      })

      this.emitter.emit('alert', {
        type: 'success',
        content: 'Les fichiers ont bien été supprimés.',
      })
      this.modal.deleteItems = false
    },
  },
}
</script>

<style scoped>
</style>
